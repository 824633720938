<template>
  <List el="md" style="max-width: 1200px">
    <List el="column-between xsm">
      <Text el="h2">My Account</Text>
    </List>
    <List el="column xxsm" v-if="user != null">
      <Btn @click="open(`https://alphaleaf-pay.burkedesigns.workers.dev/create-customer-portal-session/${user.stripeCustomerID}`)" v-if="user.stripeCustomerID != null && user.stripeCustomerID != ''">Update Billing</Btn>
    </List>
    <List el="md" v-if="user != null">
      <hr el>
      <div>
        <Btn @click="$router.push('/submit-payment');">Pick Payment Plan</Btn>
      </div>
    </List>
  </List>
</template>
<script>
// import router from "@/router";
// let filter = require("lodash/filter");
// let lowerCase = require("lodash/lowerCase");
// let includes = require("lodash/includes");

export default {
  components: {
    // Layout,
  },
  data() {
    return {
      // user: this.$store.state.main.user,
    };
  },
  
  methods: {
    open(url){
      window.open(url, '_blank')
    },
  },
  computed: {
    user(){
      return this.$store.state.main.user;
    }
    // isStripeCustomer(){
    //   return this.user.stripeCustomerID != null && this.user.stripeCustomerID != '';
    // }
  },
  mounted() {
    let pathArr = this.$route.path.split("/");
    let endPath = pathArr.pop();
    if (endPath == "upload") {
      this.$store.commit("collapseSection", false);
    } else {
      this.$store.commit("collapseSection", true);
    }

  },
};
</script>
<style lang="scss" scoped>

</style>
